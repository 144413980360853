.h-divider {
  margin: 50px auto 30px;
  width: 80%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
  box-shadow: none;
}

.h-divider .shadow:after {
  content: "";
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 23px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}
